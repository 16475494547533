@keyframes JoinLetter {
	0% {
		opacity: 0;
		letter-spacing: 1.6rem;
	}

	100% {
		opacity: 1;
		letter-spacing: 0.2rem;
	}
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(180deg);
	}
}

@keyframes gradientAnimation {
	0% {
		background-position: 0% 10%;
	}

	100% {
		background-position: 100% 50%;
	}
}
